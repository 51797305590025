import { Pipe, PipeTransform } from '@angular/core';
import { JobPlannerV2JobStabilizerData } from '@connected/data-access/fleet-service/v2';

@Pipe({
  name: 'getSupportPointsByType',
  standalone: true,
})
export class GetSupportPointsByTypePipe implements PipeTransform {
  transform(
    supportPoints: JobPlannerV2JobStabilizerData[] | undefined,
    type: 'Stabilizer' | 'Wheel',
  ): JobPlannerV2JobStabilizerData[] | undefined {
    if (supportPoints && type) {
      return supportPoints?.filter((x) => x.type?.indexOf(type) !== -1) || [];
    }
  }
}
