import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  PalChartConfiguration,
  SupportPointsLight,
} from '@paldesk/shared-lib/data-access/palchart-service-generated';
import { OutriggersFriendlyName } from '../shared/models/vizualization.model';
import { CommonModule } from '@angular/common';
import { ConnectedStabilizerValueComponent } from '../stabilizer-value/stabilizer-value.component';
import { TranslateModule } from '@ngx-translate/core';
import { PdLetDirectiveModule } from '@cdk/pd-let';
import { GetStabilizerConfigPipe } from './pipe/get-stabilizer-config.pipe';
import {
  MeasurementSystem,
  METRIC,
} from '@shared-lib/calculate-user-units-pipe';

@Component({
  selector: 'connected-stabilizer-information',
  templateUrl: './stabilizer-information.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    PdLetDirectiveModule,
    ConnectedStabilizerValueComponent,
    GetStabilizerConfigPipe,
  ],
})
export class ConnectedStabilizerInforamtionComponent {
  @Input() config: PalChartConfiguration;
  @Input() measurementSystem: MeasurementSystem = METRIC;
  @Input() stabilizers: SupportPointsLight[];

  OutriggersFriendlyName = OutriggersFriendlyName;
}
