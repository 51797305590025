import { Pipe, PipeTransform } from '@angular/core';
import { SupportPointsLight } from '@paldesk/shared-lib/data-access/palchart-service-generated';
import { JobPlannerUtils } from '../../utils/job-planner-utils';
import {
  JobPlannerV2JobStabilizerData,
  Coordinates,
} from '@connected/data-access/fleet-service/v2';

@Pipe({
  name: 'getTransfromTranslate',
  standalone: true,
})
export class GetTransfromTranslatePipe implements PipeTransform {
  transform(
    supportPoint: SupportPointsLight | JobPlannerV2JobStabilizerData,
    offset: { x: number; y: number },
    config?: Coordinates,
  ): string {
    if (config) {
      return (
        'translate(' +
        JobPlannerUtils.getXAndY(config.x, config.y, offset) +
        ')'
      );
    } else {
      return (
        'translate(' +
        JobPlannerUtils.getXAndY(
          supportPoint?.x || 0,
          supportPoint?.y || 0,
          offset,
        ) +
        ')'
      );
    }
  }
}
