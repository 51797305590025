import { Pipe, PipeTransform } from '@angular/core';
import { SupportPointsLight } from '@paldesk/shared-lib/data-access/palchart-service-generated';
import { JobPlannerUtils } from '../../utils/job-planner-utils';
import {
  JobPlannerV2JobStabilizerData,
  Coordinates,
} from '@connected/data-access/fleet-service/v2';

@Pipe({
  name: 'getPathForSupportPoints',
  standalone: true,
})
export class GetPathForSupportPointsPipe implements PipeTransform {
  transform(
    supportPoint: SupportPointsLight | JobPlannerV2JobStabilizerData,
    offset: { x: number; y: number },
    configCoordinates: Coordinates,
  ): string | undefined {
    if (supportPoint) {
      return (
        'm ' +
        JobPlannerUtils.getXAndY(
          supportPoint?.x || 0,
          supportPoint?.y || 0,
          offset,
        ) +
        ' L ' +
        JobPlannerUtils.getXAndY(
          configCoordinates.x,
          configCoordinates.y,
          offset,
        )
      );
    }
  }
}
